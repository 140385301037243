'format es6';
'use strict';

import $ from 'jquery';
import './ArrayFromPolyfill';
import ns from 'ns';

import gsap from 'gsap';

import Scrollbar from 'smooth-scrollbar';
import imagesLoaded from 'imagesloaded';
import objectFitImages from '../../node_modules/object-fit-images';

import { docReady } from './utils/docReady';
import { Animator } from '../lagrange/animation/Animator';
import { Animations } from './Animations';
import { isNativeScrolling } from './AppSettings';
import { isMobile as checkMobile } from './utils/isMobile';
import { ApplicationForm } from './ApplicationForm';
import { scrollToElem } from './utils/scrollTo';
import { isEdge, isIE11, isAllEdge } from './utils/browserDetect';

import Menu from './Menu';
import RevealText from './RevealText';
import WomenSign from './WomenSign';
import Forms from './Forms';
import Anchor from './Anchor';
import VideoPlayer from './VideoPlayer';

let sb;

docReady.then(() => {

	// Polyfill pour object-contain pour IE (ark)
	objectFitImages();

	if (isEdge([12, 13, 14])) {
		$('img').removeAttr('srcset').removeAttr('sizes');
	}

	const main = document.querySelector('[data-main-scrollbar]');

	if (main) {
		let animator = null;

		let isMobile = checkMobile(767);
		animator = new Animator();

		$(window).on('resize', () => {
			if (checkMobile(767) && !isMobile) {
				isMobile = true;
			} else if (!checkMobile(767) && isMobile) {
				isMobile = false;
			}

			animator.setAnimations(Animations.get(isMobile));
			animator.updateElements();
		}).trigger('resize');

		imagesLoaded(document.body, { background: true }, () => {
			animator.updateElements();
		});

		// if (!isNativeScrolling && !isMobile) {
		// 	main.setAttribute('data-has-scrollbar', true);
		// 	sb = Scrollbar.init(main);
		// }
		// else if (checkMobile(767) && !isNativeScrolling) {
		// 	console.log('allo');
		// 	main.setAttribute('data-has-scrollbar', true);
		// 	sb = Scrollbar.init(main, { damping: 1 });
		// }
	}

	if (isIE11) {
		main.classList.add('ie11');
	} else if (isAllEdge) {
		main.classList.add('edge');
	}

	const getSearch = ns.search.substring(3);
	const search = document.getElementById(getSearch);

	if (search && (search !== null || search !== undefined)) {
		if (!isNativeScrolling) {
			sb.scrollTo(0, 0);
		} else {
			window.scroll(0, 0);
		}

		setTimeout(() => {
			if (!isNativeScrolling) {
				sb.scrollIntoView(search);
			} else {
				scrollToElem(search, { container: $('main') });
			}
		}, 1500);
	}

	// drawSVG semble pas fonctionne sur ie11, donc on l'active juste si c'est pas ie11.
	if (!isIE11) {
		WomenSign.init();
	}

	Anchor.init();
	Menu.init();
	Forms.init();
	RevealText.init();
	VideoPlayer.init();

	console.log("all init");

	const introM = $('[data-msj]');
	introM.on('click', (e) => {
		e.preventDefault();
		introM.parents('.intro').toggleClass('active');
	});

	// Formulaire d'application
	const applicationForm = new ApplicationForm($('[data-application-form="1"]'), 'sectionFormulaire');
});
