'format es6';
'use strict';

import 'core-js/fn/array/find';
import Scrollbar from 'smooth-scrollbar';
import { TweenMax } from 'gsap';

import Ease from './Easings';

export const ELEM_BOTTOM = 'eb';
export const ELEM_TOP = 'et';
export const ELEM_CENTER = 'ec';

export const SCREEN_BOTTOM = 'sb';
export const SCREEN_TOP = 'st';
export const SCREEN_CENTER = 'sc';

/**
 * Limits a number between a range of 2 numbers
 * @param {number} num 
 * @param {number} min 
 * @param {number} max 
 */
const constrain = (num, min, max) => {
	return Math.min(Math.max(parseInt(num), min), max);
};

/**
 * Map a number between two ranges
 * 
 * @param {number} num 
 * @param {number} in_min 
 * @param {number} in_max 
 * @param {number} out_min 
 * @param {number} out_max 
 */
const map = (num, in_min, in_max, out_min, out_max) => {
	if (in_max === in_min) return out_max;
	return (((num - in_min) * (out_max - out_min)) / (in_max - in_min)) + out_min;
};

const instances = [];

export function updateAll() {
	instances.forEach((instance) => {
		instance.updateElements();
	});
}

export function Animator() {
	let animations = [];
	let elements = [];
	let contexts = [];

	const win = {
		width: 0,
		height: 0,
	};

	instances.push(this);

	function parseWhen(context, rect, when) {
		const parts = when.split('_');
		if (parts.length !== 2) throw new Error('Missing parameters in animation "when" (expects: ELEM_SCREEN)');
		
		let obj;
		switch (parts[0]) {
			default:
			case ELEM_TOP: obj = rect.top; break;
			case ELEM_CENTER: obj = rect.top + (rect.height / 2); break;
			case ELEM_BOTTOM: obj = rect.top + rect.height; break;
		}
		
		let screen;
		switch (parts[1]) {
			default:
			case SCREEN_TOP: screen = context.animator_top; break;
			case SCREEN_CENTER: screen = context.animator_top + (win.height / 2); break;
			case SCREEN_BOTTOM: screen = context.animator_top + win.height; break;
		}

		return obj - screen;
	}

	function parseRelativeVal(prop, value, elem) {
		switch (prop) {
			case 'y': return (value / 100) * elem.clientHeight;
			case 'x':
			default: return (value / 100) * elem.clientWidth;
		}
	}

	function parseVal(prop, value, elem) {
		if (typeof value === 'number') return value;

		let [total, val, unit] = value.match(/([-0-9.]+)(.+)/);
		val = parseFloat(val);

		switch (unit) {
			case 'vh': return (val / 100) * window.innerHeight;
			case 'vw': return (val / 100) * window.innerWidth;
			case '%':
			default: return parseRelativeVal(prop, val, elem);
		}
	}

	function getKeyframes(context, elem, rect, animationId, props = null, children = null) {
		let animation = animations[animationId];

		if (props) {
			animation = props;
		} else if (animation && !Array.isArray(animation)) {
			animation = animation.props;
		}

		if (!animation) return [];
		return animation.reduce((c, anim) => {
			let values = { ...anim };
			delete values.when;
			values = Object.keys(values).map(key => [key, anim[key]]);
			const from = parseWhen(context, rect, anim.when);

			return values.reduce((c2, value) => {
				const [key, val] = value;
				c[key] = c[key] || [];
				c[key].push([from, parseVal(key, val, children || elem)]);
				return c;
			}, c);
		}, {});
	};

	function getChildren(context, elem, rect, animationId) {
		if (Array.isArray(animations[animationId])) {
			return [];
		}
		
		const children = animations[animationId].children || [];

		return children.reduce((c, anim) => {
			const arr = Array.from(elem.querySelectorAll(anim.selector));
			
			arr.forEach((el) => {
				const keyframes = getKeyframes(context, elem, rect, null, anim.props, el);
				
				c.push({
					node: el,
					context,
					ease: anim.ease,
					keyframes,
					keys: Object.keys(keyframes),
				});
			});
			return c;
		}, []);
	}

	function getRect(context, elem) {
		const rect = elem.getBoundingClientRect();
		return {
			top: (rect.top - context.animator_top) + context.scrollTop,
			height: rect.height,
		};
	}

	function getContext(elem) {
		let context = null;
		let el = elem;
		while (el.parentNode && !context) {
			el = el.parentNode;
			if (el.getAttribute('data-scrollbar') || el.getAttribute('data-scrollbar') === '') {
				context = Scrollbar.get(el) || el;
			}
		}
		if (!contexts.find(ctx => context === ctx)) {
			if (el.getBoundingClientRect) {
				context.animator_top = el.getBoundingClientRect().top;
			} else {
				context.animator_top = context.targets.container.getBoundingClientRect().top;
			}
			contexts.push(context);
		}
		return context;
	}

	function constrainValues(c, prop) {
		const constrained = constrain(prop.st, prop.startOffset, prop.endOffset);
		const prc = map(constrained, prop.startOffset, prop.endOffset, 0, 1);
		if (prop.ease) {
			c[prop.key] = Ease[prop.ease](prc, prop.startValue, prop.endValue - prop.startValue, 1);
		} else {
			c[prop.key] = map(constrained, prop.startOffset, prop.endOffset, prop.startValue, prop.endValue);
		}
		return c;
	}

	function transformValues(el) {
		const st = el.context.scrollTop;
		return el.keys.map((propKey) => {
			return el.keyframes[propKey].reduce((propCarry, propVal) => {
				const [offset, value] = propVal;
				if (propCarry.startOffset === undefined || st >= offset) {
					propCarry.startOffset = offset;
					propCarry.startValue = value;

					if (propCarry.endOffset <= propCarry.startOffset) {
						propCarry.endOffset = offset;
						propCarry.endValue = value;
					}
				}
				if (propCarry.endOffset === undefined || st <= offset) {
					propCarry.endOffset = offset;
					propCarry.endValue = value;
				}
				return {
					st,
					ease: el.ease,
					key: propKey,
					...propCarry,
				};
			}, {});
		}).reduce(constrainValues, {});
	}

	function hasChanged(el, values) {
		if (!el.__lastValues) return true;
		return JSON.stringify(el.__lastValues) !== JSON.stringify(values);
	}

	function scrollNative(ctx) {
		elements.forEach((el) => {
			if (el.context !== ctx) return;
			const values = transformValues(el);
			values.force3D = true;
			if (hasChanged(el, values)) {
				TweenMax.to(el.node, 0.3, values);
				el.__lastValues = values;
			}
		});
	}

	function scroll(ctx) {
		const l = elements.length;
		let el;
		for (let i = 0; i < l; i += 1) {
			el = elements[i];
			if (el.context === ctx) {
				const values = transformValues(el);
				values.force3D = true;
				if (hasChanged(el, values)) {
					el.__lastValues = { ...values };
					TweenMax.set(el.node, values);
				}
			}
		}
	}

	function getEase(animationId) {
		return animations[animationId].ease || null;
	}

	function parseElements(list, elem) {
		const context = getContext(elem);
		const animationId = elem.getAttribute('data-animator-id');

		const rect = getRect(context, elem);

		const keyframes = getKeyframes(context, elem, rect, animationId);

		list.push({
			node: elem,
			context,
			ease: getEase(animationId),
			keyframes,
			keys: Object.keys(keyframes),
		});

		return list.concat(getChildren(context, elem, rect, animationId));
	}

	function updateContext(ctx) {
		if (ctx.animator_scrollHandler) {
			if (ctx.removeListener) {
				ctx.removeListener(ctx.animator_scrollHandler);
			} else {
				ctx.removeEventListener('scroll', ctx.animator_scrollHandler);
			}
		}
		const scrollHandler = (e) => {
			scroll(ctx, e.offset.y);
		};
		const nativeScrollHandler = (e) => {
			scrollNative(ctx, ctx.scrollTop);
		};
		
		if (ctx.addListener) {
			scrollHandler(ctx);
			ctx.animator_scrollHandler = scrollHandler;
			ctx.addListener(scrollHandler);
		} else {
			nativeScrollHandler(ctx);
			ctx.animator_scrollHandler = scrollHandler;
			ctx.addEventListener('scroll', nativeScrollHandler);
		}
	}

	this.updateElements = () => {
		contexts = [];
		win.width = window.innerWidth;
		win.height = window.innerHeight;
		const nodeList = document.querySelectorAll('[data-animator-id]');
		elements = Array.from(nodeList).reduce(parseElements, []);

		window.animator___elements = elements;

		contexts.forEach(updateContext);
	};

	this.setAnimations = (anims) => {
		animations = anims;
		this.updateElements();
	};
}
