'format es6';
'use strict';

import $ from 'jquery';

let videoOverlay;
let player;
let close;

const CLOSE_ANIMATION_TIME = 600;
const ACTIVE_CLASS = 'opened';

const TARGET_ELEMENT_DATA = 'video-btn';
const TARGET_ELEMENT_SELECTOR = `[data-${TARGET_ELEMENT_DATA}]`;
const playerBtns = $(TARGET_ELEMENT_SELECTOR);

const EVENT_NAMESPACE = 'lagrange';

function closeVideoPlayer(e) {
	e.preventDefault();

	videoOverlay.removeClass(ACTIVE_CLASS);
	setTimeout(() => {
		player.empty();
	}, CLOSE_ANIMATION_TIME);
}

function setupYoutube(e) {
	e.preventDefault();

	const clicked = $(e.currentTarget);
	const isVimeo = clicked.data('isVimeo');

	const source = isVimeo ? `https://player.vimeo.com/video/${clicked.data(TARGET_ELEMENT_DATA)}?autoplay=1` : `https://www.youtube.com/embed/${clicked.data(TARGET_ELEMENT_DATA)}?autoplay=1&rel=0&modestbranding=1&showinfo=0`;
	const html = `<iframe width="100%" height="100%" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
	
	player.empty().append(html);
	videoOverlay.addClass(ACTIVE_CLASS);
}

export default {
	init() {	
		videoOverlay = $(`
			<div class="video-overlay">
				<div class="close"><span>&times;</span></div>
				<div class="player"></div>
			</div>
		`);
		player = videoOverlay.find('.player');
		close = videoOverlay.find('.close');
		
		if (playerBtns.length === 0) return;

		$('body').append(videoOverlay);

		close.on(`click.${EVENT_NAMESPACE}.video_player`, closeVideoPlayer);
		playerBtns.on(`click.${EVENT_NAMESPACE}.video_player`, setupYoutube);
	},
};
