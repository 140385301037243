'format es6';

import $ from 'jquery';
import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';

let sb;

let menuBtn;
let mainMenu;
let closePanel;
let body;

function onScroll(e) {
	const form = document.querySelector('[data-form-space]');

	if (form) {
		if (form.getBoundingClientRect().top <= menuBtn.clientHeight) {
			menuBtn.classList.add('dark');
		} else if (form.getBoundingClientRect().top >= menuBtn.clientHeight) {
			menuBtn.classList.remove('dark');
		}
	}
}
function handleClasses() {
	menuBtn.classList.toggle('opened');
	mainMenu.classList.toggle('opened');
	closePanel.classList.toggle('opened');
}

export default {
	init() {
		menuBtn = document.querySelector('[data-mobile-menu]');
		mainMenu = document.querySelector('[data-main-menu]');
		closePanel = document.querySelector('[data-close-menupanel]');
		const anchorMenu = Array.from(mainMenu.querySelectorAll('[data-menu-anchor]'));
		body = $('body');

		console.log('ajout event');

		menuBtn.addEventListener('click', () => {
			console.log('click');
			handleClasses();
		});

		closePanel.addEventListener('click', () => {
			handleClasses();
		});

		if (anchorMenu) {
			Array.from(anchorMenu).forEach((curr) => {
				curr.addEventListener('click', handleClasses);
			});
		}

		const main = document.querySelector('[data-main-scrollbar]');

		if (!isNativeScrolling) {
			sb = Scrollbar.get(main);

			sb.addListener(onScroll);
			onScroll();
		} else {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e, true);
			});
			onScroll(null, true);
		}
	},
};
