'format es6';
'use strict';

import $ from 'jquery';
import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';

let sb;
let revealText;
let revealImg;

function onScroll(e, native = false) {
	revealText.forEach((text) => {
		if (text.getBoundingClientRect().top + text.clientHeight <= window.innerHeight) {
			text.classList.add('active');
		} else if (text.getBoundingClientRect().top > window.innerHeight) {
			text.classList.remove('active');
		}
	});

	revealImg.forEach((img) => {
		if (img.classList.contains('img-ctn') 
		&& img.getBoundingClientRect().top + (img.clientHeight) <= window.innerHeight * 1.4) {
			img.classList.add('active');
		} else if (img.getBoundingClientRect().top > window.innerHeight) {
			img.classList.remove('active');
		}
	});
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		
		revealText = Array.from(main.querySelectorAll('[data-reveal-text]'));
		revealImg = Array.from(main.querySelectorAll('[data-reveal-img]'));

		if (!isNativeScrolling && revealText) {
			sb = Scrollbar.get(main);

			sb.addListener(onScroll);
			onScroll();

		} else if (isNativeScrolling && revealText) {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e, true);
			});
			onScroll(null, true);
		}
	},
};
