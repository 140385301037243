'format es6';
'use strict';

import $ from 'jquery';

function removeError(e) {
	const el = $(e.currentTarget);
	
	if (el.hasClass('error')) {
		el.removeClass('error');
	}
}

function handleInputChange(e) {
	const el = $(e.currentTarget);

	removeError(e);

	if (e.type === 'click' || e.type === 'focus') {
		el.removeClass('empty');
		el.siblings().addClass('visible');
		return;
	}

	if (el.val() === '') {
		el.addClass('empty');
		el.siblings().removeClass('visible');
	} else {
		el.removeClass('empty');
		el.siblings().addClass('visible');
	}
}

export default {
	init() {
		const input_fields = $('input[type=text], input[type=tel], input[type=email], input[type=number], input[type=password], textarea');

		input_fields.on('keypress focus blur change input click', handleInputChange).trigger('blur');
		
		$('select').on('keypress blur change input', removeError).trigger('blur');

		// Input de type fichier -> afficher le nom du fichier dans le label lorsqu'il y a en a un sélectionné
		$('input[type=file]').on('change', (e) => {
			const filename = e.currentTarget.files[0] ? e.currentTarget.files[0].name : '<span><i class="icon-upload"></i>Choisir un fichier </span>';
			$(e.currentTarget).next('label').html(`<span> ${filename} </span>`);
		});
	},
};
