'format es6';
'use strict';
import $ from 'jquery';
import { scrollToElem } from './utils/scrollTo';
import { isNativeScrolling } from './AppSettings';
import gsap, { TweenMax, TimelineMax } from 'gsap';

import Scrollbar from 'smooth-scrollbar';

// to do: faudrait refactorisez le code. Trop de variable global
const HIDING = 0;
const REVEALING = 1;
const STOPPED = 2;

let sb;
let currentActiveSection;
let anchorMenu;
let anchor;
let tl;
let anchorText;
let anchorArr;
let nextLabel;
let timelineState = STOPPED;

function changeLabel() {
	anchorText.innerHTML = nextLabel;
}

function changeState(nextState) {
	// console.log(`set state to ${nextState}`);
	timelineState = nextState;
	if (timelineState === STOPPED && nextLabel !== anchorText.innerHTML) {
		tl.restart();
	}
}

const getCurrentSection = (() => {
	let sections;
	return () => {
		sections = sections || document.querySelectorAll('[data-section]');

		return Array.from(sections).reduce((found, obj) => {
			if (obj.getBoundingClientRect().top <= anchor.getBoundingClientRect().bottom) {
				return obj;
			}
			return found;
		}, null);
	};
})();

function onScroll() {
	/* detect la section */
	const current = getCurrentSection();

	if (current == null) return; // ajout pour le bandeau fixe au top pour le corona virus

	/* faire la transition si ce n'est pas la même section */
	if (current !== currentActiveSection) {
		currentActiveSection = current;


		if (currentActiveSection.hasAttribute('data-application-form')) {
			anchor.classList.add('dark');
		} else if (anchor.classList.contains('dark')) {
			anchor.classList.remove('dark');
		}

		if (currentActiveSection.hasAttribute('data-anchorname')) {
			nextLabel = currentActiveSection.getAttribute('data-anchorname');

			if (timelineState === STOPPED) {
				tl.restart();
			}
		}
	}
}

function onAnchorClick(e) {
	const clicked = e.currentTarget;

	if (clicked.hasAttribute('data-inpage')) {
		e.preventDefault();
	}

	const id = clicked.getAttribute('href').substring(1);
	const target = document.getElementById(id);
	anchorMenu.forEach((curr) => {
		if (curr !== clicked) {
			curr.classList.remove('active');
		} else {
			clicked.classList.add('active');
		}
	});

	if (target) {
		if (!isNativeScrolling) {
			sb.scrollIntoView(target);
		} else {
			scrollToElem(target, { container: $('main') });
		}
	}
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		anchorMenu = Array.from(document.querySelectorAll('[data-menu-anchor]'));

		tl = new TimelineMax();
		anchor = document.querySelector('[data-anchor]');

		if (!anchor) return;

		[anchorText] = Array.from(anchor.getElementsByClassName('anchorLabel'));

		const revealDiv = anchor.getElementsByClassName('reveal');


		tl.add(() => {
			changeState(HIDING);
		})
			.to(revealDiv, 0.5, { scaleX: 1 })
			.add(() => {
				changeLabel();
				changeState(REVEALING);
			})
			.to(revealDiv, 0, { transformOrigin: 'right center' })
			.to(revealDiv, 0.5, { scaleX: 0, delay: 0.1 })
			.to(revealDiv, 0, { transformOrigin: 'left center' })
			.add(() => {
				changeState(STOPPED);
			});
		tl.stop();

		if (anchorMenu) {
			Array.from(anchorMenu).forEach((curr) => {
				curr.addEventListener('click', onAnchorClick);
			});
		}

		if (!isNativeScrolling && anchorMenu) {

			sb = Scrollbar.get(main);
			sb.addListener(onScroll);
			onScroll();
		} else if (isNativeScrolling && anchorMenu) {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e, true);
			});
			onScroll(null, true);
		}
	},
};
