'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'Promise';

const name = 'probonotaxi';
const ns = window[name] = (window[name] || {});

ns.window = $(window);

export default ns;

