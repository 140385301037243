import $ from 'jquery';

/**
 * Interactivité du formulaire d'application à la bourse
 */
export class ApplicationForm {

	/**
	 * Constructeur
	 * @param {jQuery} rootElement - Élément racine du formulaire
	 * @param {string} sectionId - ID de toute la zone du formulaire
	 */
	constructor(rootElement, sectionId) {

		/**
		 * ID de toute la zone du formulaire
		 * @type {string}
		 */
		this.idSectionFormulaire = sectionId;

		/**
		 * Élément racine du formulaire (Doit être un <form>)
		 * @type {jQuery}
		 */
		this.rootElement = rootElement;

		/**
		 * Classe à ajouter sur un champ pour indiquer que la valeur saisie est invalide
		 * @type {string}
		 */
		this.errorClass = 'invalid-answer';

		/**
		 * Taille de fichier maximale autorisée, en MB
		 * @type {number}
		 */
		this.maximumFileSize = 2;

		/**
		 * Nombre d'années d'expérience maximales autorisées
		 * @type {number}
		 */
		this.maxYearsExperience = 100;

		/**
		 * ID du content-block du formulaire
		 * @type {number}
		 */
		this.blockId = rootElement.attr('data-application-form');

		/**
		 * Élément du numéro de l'étape en filigramme derrière le formulaire
		 * @type {jQuery}
		 */
		this.stepNumber = $('[data-step-number]');

		/**
		 * Élément du nombre d'étapes au total
		 * @type {jQuery|HTMLElement}
		 */
		this.numberOfStepsTxt = $('[data-number-of-steps]');

		/**
		 * Élément du nom de l'étape où est rendu l'utilisateur dans le formulaire
		 * @type {jQuery|HTMLElement}
		 */
		this.stepName = $('[data-nom-etape]');

		/**
		 * Titre au-dessus du formulaire
		 * @type {jQuery|HTMLElement}
		 */
		this.formTitle = $(`[data-application-form-title=${this.blockId}]`);

		/**
		 * Écrans de questions
		 * @type {jQuery|HTMLElement}
		 */
		this.questionsScreens = $('[data-questions-screen]', this.rootElement);

		/**
		 * Conteneur de l'animation de chargement
		 * @type {jQuery|HTMLElement}
		 */
		this.loadingAnimation = $('[data-form-loader]', this.rootElement);

		/**
		 * Texte indiquant le pourcentage de chargement sur l'écran de chargement
		 * @type {jQuery|HTMLElement}
		 */
		this.loadingPrctText = $('[data-loading-progress]', this.rootElement);

		/**
		 * Liste de tous les inputs
		 * @type {Array}
		 */
		this.allInputs = [];

		this.questionsScreens.each((index, element) => {
			// Enregistrement de tous les inputs dans une propriété de leur écran respectif
			const screenInputs = $('input, textarea', element);
			element.associatedInputs = screenInputs;

			// Enregistrement des inputs dans une liste globale
			$.each(screenInputs, (s, node) => this.allInputs.push(node));
		});

		/**
		 * Conteneur des boutons pour changer d'écran + bulles montrant le progrès actuel
		 * @type {jQuery|HTMLElement}
		 */
		this.progressionWrapper = $('[data-progression-wrapper]', this.rootElement);

		/**
		 * Conteneur du numéro/nom de l'étape actuelle
		 * @type {jQuery|HTMLElement}
		 */
		this.currentStepWrapper = $('[data-current-step-wrapper]');

		/**
		 * Bouton pour retour à l'écran précédent
		 * @type {jQuery|HTMLElement}
		 */
		this.previousScreenBtn = $('[data-prev-screen-btn]');

		/**
		 * Bouton changeant d'écran
		 * @type {jQuery|HTMLElement}
		 */
		this.nextScreenBtn = $('[data-next-screen-btn]');

		/**
		 * Bouton soumettant le formulaire
		 * @type {jQuery|HTMLElement}
		 */
		this.submitFormBtn = $('[data-submit-form]');

		/**
		 * Écran de questions auquel on est rendus dans le formulaire (indexé à 1)
		 * @type {number}
		 */
		this.currentQuestionsScreen = 1;

		/**
		 * Vitesse de transition entre les écrans, en ms
		 * @type {number}
		 */
		this.transitionSpeed = 400;

		this.initializeForm();
	}

	/**
	 * Mets le formulaire à son état initial
	 */
	initializeForm() {
		// Affichage seulement des éléments nécessaires au départ
		this.questionsScreens.each((index, element) => {
			if (index === 0) {
				return;
			}
			$(element).hide();
		});

		// Setter la hauteur du formulaire
		// this.setFormHeight();

		// Initialisation des éléments interactifs
		this.disableActionButtons();
		this.submitFormBtn.hide();
		this.stepNumber.text(this.currentQuestionsScreen);
		this.numberOfStepsTxt.text(this.questionsScreens.length);
		this.loadingAnimation.hide();
		this.previousScreenBtn.hide();

		// Ajout des écouteurs d'évènement
		this.attachEvents();
	}

	/**
	 * Défini la hauteur du formulaire et l'applique au conteneur
	 */
	setFormHeight() {
		$(window).on('resize.mp.tabs', () => {
			let h = 0;

			$('[data-tab-item-child]').each((elem) => {
				if (window.matchMedia('(min-width: 999px)').matches) { /*desktop*/
					$('[data-tab-item-child]').children().each((el) => {
						const current = $('[data-tab-item-child]').children().eq(el).outerHeight();

						if (h < current) {
							h = current;
						}
					});
				} else {
					const current = $('[data-tab-item-child]').eq(elem).height();

					if (h < current) {
						h = current;
					}
				}
			});

			$(this.idSectionFormulaire).css('height', h);
		}).resize();
	}

	/**
	 * Attache tous les écouteurs d'évènements aux éléments qui en ont besoin
	 */
	attachEvents() {
		// Boutons de changement d'écran
		this.nextScreenBtn.on('click', this.goToNextQuestionsScreen.bind(this));
		this.previousScreenBtn.on('click', this.goToPreviousScreen.bind(this));

		// Bouton de soumission
		this.submitFormBtn.on('click', this.submitForm.bind(this));

		// Évènements lors de saisi d'informations dans les inputs
		const allInputs = $(this.allInputs).on('change input');
		allInputs.on('change input', this.onUserInput.bind(this));
		allInputs.on('blur', this.indicateFieldError.bind(this));
	}

	/**
	 * Supprime tous les écouteurs d'évènement
	 */
	removeEvents() {
		this.nextScreenBtn.off('click', this.goToNextQuestionsScreen.bind(this));
		this.previousScreenBtn.off('click', this.goToPreviousScreen.bind(this));

		this.submitFormBtn.off('click', this.submitForm.bind(this));

		// $(this.allInputs).off('change input', this.onUserInput.bind(this));
		// $(this.allInputs).off('blur', this.indicateFieldError.bind(this));
	}

	/**
	 * Se rend à l'écran de questions suivant
	 * @param {event} e - Bouton qui a triggeré la fonction
	 */
	goToNextQuestionsScreen(e) {
		e.preventDefault();

		if (this.currentQuestionsScreen >= this.questionsScreens.length) {
			return;
		}

		const currentScreen = $(this.questionsScreens[this.currentQuestionsScreen - 1]);
		const nextScreen = $(this.questionsScreens[this.currentQuestionsScreen]);

		this.currentQuestionsScreen++;

		// Transition
		this.updateQuestionsScreen(currentScreen, nextScreen);

		// Désactive les boutons suivant/soumettre et affiche le bouton précédent si nécessaire
		if (this.currentQuestionsScreen === 2) {
			this.previousScreenBtn.fadeIn(this.transitionSpeed);
		}

		// Si dernier écran, remplace le bouton suivant par soumettre
		if (this.currentQuestionsScreen >= this.questionsScreens.length) {
			this.nextScreenBtn.fadeOut(this.transitionSpeed, () => {
				this.submitFormBtn.fadeIn(this.transitionSpeed);
			});
		}

		// Désactive le bouton suivant si les champs ne sont pas remplis
		if (!this.canGoToNextScreen()) {
			this.disableActionButtons();
		}
	}

	/**
	 * Retourne à l'écran précédent
	 * @param {Event} e - Bouton qui a triggeré la fonction
	 */
	goToPreviousScreen(e) {
		e.preventDefault();

		if (this.currentQuestionsScreen <= 0) {
			return;
		}

		const currentScreen = $(this.questionsScreens[this.currentQuestionsScreen - 1]);
		const previousScreen = $(this.questionsScreens[this.currentQuestionsScreen - 2]);

		this.currentQuestionsScreen--;

		// Gestion des boutons
		if (this.currentQuestionsScreen === 1) {
			this.previousScreenBtn.fadeOut(this.transitionSpeed);
		}
		if (this.currentQuestionsScreen === this.questionsScreens.length - 1) {
			this.submitFormBtn.fadeOut(this.transitionSpeed, () => {
				this.nextScreenBtn.fadeIn(this.transitionSpeed);
			});
		}
		this.enableActionButtons();

		// Transition
		this.updateQuestionsScreen(currentScreen, previousScreen);
	}

	/**
	 * Fait la transition entre les écrans et update le texte de # et nom d'étape
	 * @param {jQuery} currentScreen - Écran actuel
	 * @param {jQuery} screenToGoTo - Écran auquel on veut aller
	 */
	updateQuestionsScreen(currentScreen, screenToGoTo) {
		// Transition
		currentScreen.fadeOut(this.transitionSpeed, () => {
			screenToGoTo.fadeIn(this.transitionSpeed);
		});

		// Update le # de l'écran et son nom
		this.stepNumber.text(this.currentQuestionsScreen);
		this.stepName.text(screenToGoTo.data('step-name'));
	}

	/**
	 * Lorsque l'utilisateur saisi des données dans les champs du formulaire
	 * @param {event} e - Évènement
	 */
	onUserInput(e) {
		const elem = $(e.currentTarget);

		// Si tous les champs de l'écran actuel sont remplis, active le bouton prochaine étape/soumettre
		if (this.canGoToNextScreen()) {
			this.enableActionButtons();
		} else {
			this.disableActionButtons();
		}

		// Pour les fichiers seulement : si le fichier ne répond pas aux critères, affiche un message d'erreur
		if (e.currentTarget.type === 'file') {
			if (!this.validateFieldInput(e.currentTarget) && e.currentTarget.files.length > 0) {
				elem.siblings('[data-error-message]').removeClass('hidden');
			} else {
				elem.siblings('[data-error-message]').addClass('hidden');
			}
		}
	}

	/**
	 * Vérifie si le champ est correctement rempli pour indiquer à l'utilisateur qu'il s'est trompé si la valeur saisie est erronée
	 * @param {HTMLElement} e - Input à valider
	 */
	indicateFieldError(e) {
		const elem = $(e.currentTarget);
		const inputIsValid = this.validateFieldInput(e.currentTarget);
		const hasErrorClass = elem.hasClass(this.errorClass);

		if (!inputIsValid && !hasErrorClass) {
			elem.addClass(this.errorClass);
		} else if (inputIsValid && hasErrorClass) {
			elem.removeClass(this.errorClass);
		}
	}

	/**
	 * Active les boutons suivant/soumettre
	 */
	enableActionButtons() {
		this.nextScreenBtn.attr({ disabled: false });
		this.submitFormBtn.attr({ disabled: false });
	}

	/**
	 * Désactive les boutons suivant/soumettre
	 */
	disableActionButtons() {
		this.nextScreenBtn.attr({ disabled: true });
		this.submitFormBtn.attr({ disabled: true });
	}

	/**
	 * Vérifie si tous les champs de l'écran actuel sont remplis, si oui on peut passer à l'écran suivant
	 * @returns {boolean}
	 */
	canGoToNextScreen() {
		const mesInputsInvalides = this.questionsScreens[this.currentQuestionsScreen - 1].associatedInputs
			.toArray()
			.filter(input => !this.validateFieldInput(input));

		return mesInputsInvalides.length === 0;
	}

	/**
	 * Vérifie si le input saisi dans le champ est valide ou non
	 * @param {HTMLElement} input - Input dont on veut valider la valeur
	 * @returns {boolean} - Valeur saisie valide ou pas
	 */
	validateFieldInput(input) {
		// Si c'est Recaptcha, retourne automatiquement true (on s'occupe pas de le valider)
		if (input.id === 'g-recaptcha-response') {
			return true;
		}

		const validationType = input.getAttribute('data-validation-type');
		const numberRegex = /^[0-9]+$/;
		const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		switch (validationType) {
		case 'email':
			return !!(input.value.match(emailRegex));
		case 'number':
			const numberParsed = parseInt(input.value, 10);
			return input.value.match(numberRegex) && numberParsed > 0 && numberParsed <= this.maxYearsExperience;
		case 'empty':
			return !(input.value === '');
		case 'checked':
			return input.checked;
		case 'textarea-250':
			return input.value.length <= input.maxLength;
		case 'pdfFile':
			if (input.files.length !== 0) {
				return input.files[0].type === 'application/pdf' && input.files[0].size <= this.maximumFileSize * Math.pow(1024, 2);
			}
			break;
		case 'none':
			return true;
		default:
			return false;
		}
		return false;
	}

	/**
	 * Affiche/cache le message d'erreur lorsque l'utilisateur sélectionne un fichier pour le champ input
	 * @param {HTMLElement} e
	 */
	checkFileInput(e) {

	}

	/**
	 * Lorsque l'utilisateur soumet le formulaire
	 * Envoie le formulaire par AJAX au back-end
	 * @param {event} e - émetteur de l'évènement qui a triggeré la méthode
	 */
	submitForm(e) {
		e.preventDefault();

		// Cache les options de changement d'écran
		this.progressionWrapper.fadeOut(this.transitionSpeed / 2);
		this.currentStepWrapper.fadeOut(this.transitionSpeed / 2);

		// Affiche animation chargement
		$(this.questionsScreens[this.currentQuestionsScreen - 1]).hide(0, () => {
			this.loadingAnimation.show(0);
		});

		// Requête AJAX pour l'envoi du formulaire + affichage de l'écran de fin selon réussite/échec
		// https://stackoverflow.com/questions/166221/how-can-i-upload-files-asynchronously
		const that = this;
		const formData = new FormData(this.rootElement[0]);
		$.ajax({
			url: this.rootElement.attr('action'),
			method: 'POST',
			data: formData,
			dataType: 'json',
			contentType: false,
			processData: false,
			xhr() {
				const xhrRequest = $.ajaxSettings.xhr();
				if (xhrRequest.upload) {
					xhrRequest.upload.addEventListener('progress', (progress) => {
						if (progress.lengthComputable) {
							const loadingPrct = Math.round(progress.loaded / progress.total * 100);
							that.loadingPrctText.text(`${loadingPrct} %`);
						}
					}, false);
				}
				return xhrRequest;
			},
			success(success) {
				if (!success.error) {
					that.showEndScreen(true);
				} else {
					that.showEndScreen(false);
				}
			},
			error(error) {
				that.showEndScreen(false);
			},
		});
	}

	/**
	 * Affiche l'écran de fin
	 * @param {boolean} wasSuccess - Est-ce que l'envoi du formulaire a été une réussite ou non
	 */
	showEndScreen(wasSuccess) {
		// Affichage écran fin
		this.loadingAnimation.hide(0, () => {
			// this.endScreen.fadeIn(this.transitionSpeed);
			this.rootElement.hide(0);
		});

		// Centrer le texte
		$('[data-application-form-wrapper]').addClass('application-form_completed');

		// Changement des textes
		if (wasSuccess) {
			this.formTitle.text('Votre candidature a été envoyée avec succès !');
			// this.endScreenMessage.text('Vous recevrez de nos nouvelles si vous êtes choisie.');
		} else {
			this.formTitle.text('Un problème s\'est produit lors de l\'envoi de votre candidature');
			// this.endScreenMessage.text('Veuillez réessayer plus tard, merci!');
		}
	}
}
