'format es6';
'use strict';

import {
	ELEM_BOTTOM,
	ELEM_TOP,
	ELEM_CENTER,
	SCREEN_BOTTOM,
	SCREEN_TOP,
	SCREEN_CENTER,
} from '../lagrange/animation/Animator';

export const Animations = {
	get(isMobile) {
		const animations = {};
		
		animations.semi_fixed = {
			children: [
				{
					selector: '.application-form',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? 0 : '-50%',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_BOTTOM}`,
							y: isMobile ? 0 : 0,
						},
					],
				},
			],
		};

		animations.msj_bg = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: '-21%',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '20%',
			},
		];
	
		animations.single_column = {
			children: [
				{
					selector: '.graphic-element',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							x: '-20vh',
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							x: '0vh',
						},
					],
				},
				{
					selector: '.title',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: '10vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: '-5vh',
						},
					],
				},				
				{
					selector: '.text',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: '10vh',
						},
						{
							when: `${ELEM_TOP}_${SCREEN_CENTER}`,
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: '-5vh',
						},
					],
				},
			],
		};

		animations.two_columns = {
			children: [
				{
					selector: '.bg',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							scaleX: 0,
						},
						{
							when: `${ELEM_TOP}_${SCREEN_CENTER}`,
							scaleX: 1,
						},
					],
				},
				{
					selector: '.two-columns-wrapper',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '8%' : '20vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '-8%' : '-20vh',
						},
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							// opacity: 0,
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_BOTTOM}`,
							// opacity: 1,
						},
					],
				},	
			],
		};
		
		animations.horizontal_scroll = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					x: '10vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					x: '0vh',
				},
			],
		};

		animations.play_btn = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					opacity: 0,
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					opacity: 1,
				},
			],
		};

		animations.video_left = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					scale: 0.7,
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					scale: 1,
				},
			],
			children: [
				{
					selector: 'img',
					ease: 'easeInOutCubic',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							scale: 1 / 0.7,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							scale: 1,
						},
					],
				},
			],
		};

		animations.video_text = {
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: '5vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_BOTTOM}`,
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-5vh',
				},
			],
		};

		animations.committee = {
			children: [
				{
					selector: '.title',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '10%' : '10vh',
						},
						{
							when: `${ELEM_TOP}_${SCREEN_TOP}`,
							y: isMobile ? '-5%' : '-10vh',
						},
					],
				},			
				{
					selector: '.text',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '10%' : '15vh',
						},
						{
							when: `${ELEM_TOP}_${SCREEN_TOP}`,
							y: isMobile ? '-5%' : '-10vh',
						},
					],
				},			
				{
					selector: '.bottom-content',
					props: [
						{
							when: `${ELEM_CENTER}_${SCREEN_BOTTOM}`,
							y: isMobile ? '25%' : '15vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '10%' : '-10vh',
						},
					],
				},
			],
		};

		animations.person_wrapper = {
			children: [
				{
					selector: '.person:nth-child(1)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '5%' : '35vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '0%' : '-40vh',
						},
					],
				},
				{
					selector: '.person:nth-child(2)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '15%' : '20vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '-10%' : '-5vh',
						},
					],
				},
				{
					selector: '.person:nth-child(3)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '25%' : '20vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '0%' : '-18vh',
						},
					],
				},
				{
					selector: '.person:nth-child(4)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '35%' : '30vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '-10%' : '-8vh',
						},
					],
				},
				{
					selector: '.person:nth-child(5)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: isMobile ? '45%' : '35vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: isMobile ? '0%' : '-40vh',
						},
					],
				},
			],
		};
		animations.quote = {
			children: [
				{
					selector: '.graphic-element',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							x: '-10vh',
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							x: '0vh',
						},
					],
				},
				{
					selector: '.title',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: '10vh',
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: '-5vh',
						},
					],
				},				
				{
					selector: '.text',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: '10vh',
						},
						{
							when: `${ELEM_TOP}_${SCREEN_CENTER}`,
						},
						{
							when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
							y: '-5vh',
						},
					],
				},
			],
		};

		
		return animations;
	},
};
