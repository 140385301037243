'format es6';
'use strict';

import $ from 'jquery';
import { TweenLite } from 'gsap';
import './DrawSVGPlugin';
import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';

let sb;
let drawImage;
let animate = false;

function onScroll(e) {
	if (drawImage.getBoundingClientRect().top + (drawImage.clientHeight / 2) <= window.innerHeight && !animate) {
		animate = true;
		TweenLite.to('#round', 0.7, { drawSVG: '100%' });
		TweenLite.to('#horizontal-line', 0.5, { drawSVG: '100%', delay: 0.5 });
		TweenLite.to('#vertical-line', 0.5, { drawSVG: true, delay: 0.7 });
	}
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		drawImage = main.querySelector('[data-draw-img]');

		if (!isNativeScrolling && drawImage) {
			sb = Scrollbar.get(main);

			sb.addListener(onScroll);
			onScroll();
		} else if (isNativeScrolling && drawImage) {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e);
			});
			onScroll(null);
		}
	},
};
