'format es6';
'use strict';

import { isIE11, isFirefox, isMac, iosVersion } from './utils/browserDetect';
import { isMobile as checkMobile } from './utils/isMobile';

const mobile = checkMobile(767);
// export const isNativeScrolling = isIE11 || (isFirefox && isMac && window.devicePixelRatio > 1) || (iosVersion !== null && iosVersion <= 9) || mobile;
export const isNativeScrolling = true;

// console.log('isNativeScrolling', isNativeScrolling);
